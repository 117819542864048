@import 'shared-web/styles/variables';

.rental_unit {
  width: 100%;

  .tenants_names {
    max-width: 100%;
    position: relative;
    display: inline-flex;
  }

  .tenant_display_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .given_reference {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-right: 3px;
  }
}

.address {
  width: 100%;

  span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.status {
  display: flex;
  align-items: center;
  padding-left: $xs;

  &_label {
    margin-left: $m;
  }

  &_disabled {
    color: $silver;
  }

  &_circle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    size: 14px;
    min-width: 26px;
    height: 26px;
    border-radius: 13px;

    span {
      padding: 2px $xs;
    }
  }

  .status_silver {
    background-color: $status_silver;
  }

  .status_red {
    background-color: $status_red;
  }

  .status_orange {
    background-color: $status_orange;
  }

  .status_green {
    background-color: $status_green;
  }

  .status_blue {
    background-color: $status_blue;
  }
}

.details {
  &_text {
    overflow: hidden;
    display: flex;

    .status {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.tooltip {
  --bs-tooltip-max-width: 100%;
}

.dropdown {
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;

  .disabled {
    color: $silver;

    &:hover {
      background-color: transparent;
      color: $silver;
    }
  }
}
