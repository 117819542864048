@import 'shared-web/styles/variables';

.container {
  width: 100%;
  height: 360px;
}

.account_owners_section {
  margin-bottom: $l;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $super_light_grey;
  margin: $m 0;

  .sum {
    font-size: $m;

    .text {
      color: $black;
      font-weight: bold;
    }

    .sum_amount {
      color: $dim;
      font-weight: normal;
    }
  }
}

.table_header {
  display: flex;
  font-weight: bold;
  padding-bottom: $s;
  border-bottom: solid 1px $lighter_grey;
}

.table_body {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 330px;
}

.table_row {
  display: flex;
  align-items: center;
  min-height: $xxl2;
}

.table_row:not(:last-of-type) {
  border-bottom: solid 1px $lighter_grey;
}

.date {
  width: 71px;
  margin-right: $s;
}

.sender_receiver {
  width: 162px;
  margin-right: $s;

  .counterparty_name {
    font-weight: bold;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .counterparty_iban {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.remittance_information {
  width: 253px;
  margin-right: $s;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.amount {
  width: 62px;
  text-align: right;
  font-weight: bold;
  margin-right: $s;
}

.payout {
  color: $alert;
}

.placeholder {
  display: flex;
  flex-direction: column;
  height: 330px;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.4px;

  span {
    font-weight: bold;
  }
}
